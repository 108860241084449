import './why-we-screen.scss';

import poster1 from '../../../public/posters/video1.jpg';
import poster2 from '../../../public/posters/video2.jpg';
import poster3 from '../../../public/posters/video3.jpg';
import poster4 from '../../../public/posters/video4.jpg';
import poster5 from '../../../public/posters/video5.jpg';
import poster6 from '../../../public/posters/video6.jpg';


export default function WhyWeScreen() {

    return (
        <section className="why-we-screen__wrapper">
            <div className="why-we-screen__container">
                <div className="why-we-screen__why">Почему</div>
                <div className="why-we-screen__we">Мы?</div>
                <div className="why-we-screen__cards">
                    <div className="why-we-screen__card">
                        <div className="why-we-screen__video-container">
                            <video
                                poster={poster1}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-1.webm" type="video/webm" />
                                <source src="/videos/video-1.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Чистота и защита
                        </div>
                        Клиника имеет медицинскую лицензию, отвечает всем санитарным нормам.
                        Всё оборудование имеет особое медицинское назначение.
                    </div>
                    <div className="why-we-screen__card">
                    <div className="why-we-screen__video-container">
                            <video
                                poster={poster2}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-2.webm" type="video/webm" />
                                <source src="/videos/video-2.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Удобное расположение и комфорт
                        </div>
                        Наша клиника находится в самом сердце Москвы.
                        Люсиновская улица, 68 (м. Тульская).
                        Мы делаем все, чтобы посещение было максимально
                        комфортным и окружаем каждого пациента вниманием и заботой.
                    </div>
                    <div className="why-we-screen__card">
                    <div className="why-we-screen__video-container">
                            <video
                                poster={poster3}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-5.webm" type="video/webm" />
                                <source src="/videos/video-5.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Комплексный подход
                        </div>
                        В нашей клинике для вас будут индивидуально разработаны уникальные программы,
                        выключающие домашних уход, все виды анализов (полный Check Up).
                        Это позволяют минимизировать временные и финансовые затраты.
                    </div>
                    <div className="why-we-screen__card">
                    <div className="why-we-screen__video-container">
                            <video
                                poster={poster4}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-3.webm" type="video/webm" />
                                <source src="/videos/video-3.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Современное оборудование
                        </div>
                        Мы используем инновационное оборудование
                        в дерматологической медицине, обладающее
                        мировым признанием и соответствующей
                        сертификацией.
                    </div>
                    <div className="why-we-screen__card">
                    <div className="why-we-screen__video-container">
                            <video
                                poster={poster5}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-4.webm" type="video/webm" />
                                <source src="/videos/video-4.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Безопасность пациентов
                        </div>
                        Сертифицированные препараты проверенных европейских производителей,
                        профессиональная косметика, представленная на мировом рынке.
                    </div>
                    <div className="why-we-screen__card">
                    <div className="why-we-screen__video-container">
                            <video
                                poster={poster6}
                                controls
                                preload="metadata"
                                className="why-we-screen__video"
                            >
                                <source src="/videos/video-6.webm" type="video/webm" />
                                <source src="/videos/video-6.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div className="why-we-screen__header">
                            Эстетика
                        </div>
                        Перед процедурой врач изучает каждого пациента,
                        проводит консультацию, узнает потребности, цели,
                        ожидания, внутренние мотивы, чтобы максимально близко подобрать соответствующее решение их проблем.
                    </div>
                </div>
            </div>
        </section>
    );
};