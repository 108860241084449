import './care-screen.scss';

import care from '../../../public/images/abagi.png';
import obagi from '../../../public/images/obagi1.jpg';
import careWebP from '../../../public/images/abagi.webp';
import obagiWebP from '../../../public/images/obagi1.webp';


export default function CareScreen() {

    return (
        <section className="care-screen__wrapper">
            <div className="care-screen__container">
                <h1>Уход</h1>
                <div className="care-screen__top">
                    <div className="care-screen__image1-container">
                        <picture>
                            <source srcSet={careWebP} type="image/webp" />
                            <img src={care} alt="" className="care-screen__image1" />
                        </picture>
                    </div>
                    <div>
                        <div className="care-screen__header">
                            Программа по возвращению коже здоровья.
                        </div>
                        <p>
                            Индивидуально подобранный профессиональный домашний уход поможет решить ряд проблем связанных с кожей.
                            Как минимум, придаст ей лоска и шелковистости.
                            Как максимум, поможет убрать пигментацию, кератоз покраснения, воспаления,
                            оздоровить и реанимировать после воздейтсвия агрессивных факторов, с учетом индивидуальных особенностей.
                        </p>
                    </div>
                </div>
                <div className="care-screen__bottom">
                    <div>
                        <p>
                            Программа здоровой кожи начинается с правильного подбора средств.
                            Чистая, от дневного загрязнения и секреции перемешанной с косметикой,
                            она способна отплатить вам красотой и сиянием.
                        </p>
                    </div>
                    <picture className="care-screen__picture2">
                        <source srcSet={obagiWebP} type="image/webp" />
                        <img src={obagi} alt="" className="care-screen__image2" />
                    </picture>
                </div>
            </div>
        </section>
    );
};