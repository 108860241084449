import Accordion from '@material-ui/core/Accordion';
import { Element } from 'react-scroll';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import './prices-screen.scss';


export default function PricesScreen() {

    return (
        <Element name="prices" className="prices-screen__wrapper">
            <div className="prices-screen__container">
                <h2>Цены</h2>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">ULTRAFORMER MPT</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Периорбитальная зона</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Средняя треть лица</span>
                                <hr />
                                <span>25'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Нижняя треть лица</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Нижняя треть с субментальной областью</span>
                                <hr />
                                <span>35'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Все лицо</span>
                                <hr />
                                <span>70'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея</span>
                                <hr />
                                <span>90'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея + декольте</span>
                                <hr />
                                <span>110'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Игольчатый RF Vivace</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Веки</span>
                                <hr />
                                <span>10'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо</span>
                                <hr />
                                <span>25'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея + декольте</span>
                                <hr />
                                <span>40'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Живот</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Колени</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Бедра</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">RF лифтинг MORPHEUS-8</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Лицо</span>
                                <hr />
                                <span>40'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея</span>
                                <hr />
                                <span>50'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лицо + шея + декольте</span>
                                <hr />
                                <span>60'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Живот</span>
                                <hr />
                                <span>60'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Колени</span>
                                <hr />
                                <span>40'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Бедра</span>
                                <hr />
                                <span>60'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Биоревитализация</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Neauvia Hydro delux 2.5ml</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia Hydro delux 5ml</span>
                                <hr />
                                <span>27'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Novacutan s-bio/y-bio 2ml</span>
                                <hr />
                                <span>17'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Profhilo 2ml</span>
                                <hr />
                                <span>18'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Meso-Wharton 1.5ml</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Meso-Xanthin</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Meso eye 1ml</span>
                                <hr />
                                <span>17'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Viscoderm 0.8% 1ml</span>
                                <hr />
                                <span>12'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Plinest 2ml</span>
                                <hr />
                                <span>17'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Plinest fast</span>
                                <hr />
                                <span>17'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Nithya Shield</span>
                                <hr />
                                <span>13'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Nithya Smooth</span>
                                <hr />
                                <span>13'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Nithya Stimulate</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Ботулинотерапия</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Диспорт Ипсен 300ед</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Диспорт Ипсен 500ед</span>
                                <hr />
                                <span>35'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Релатокс 50ед</span>
                                <hr />
                                <span>18'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Релатокс 100ед</span>
                                <hr />
                                <span>25'000</span>
                            </div>
                            <br /><br />
                            <h5>Примерные цены по зонам Диспорт:</h5>
                            <div className="prices-screen__text-container">
                                <span>Лоб + межбровье + глаза</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Шея лифтинг "Ниффертити"</span>
                                <hr />
                                <span>12'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лечение бруксизма</span>
                                <hr />
                                <span>13'000</span>
                            </div>
                            <br /><br />
                            <h5>Примерные цены по зонам Релатокс:</h5>
                            <div className="prices-screen__text-container">
                                <span>Лоб + межбровье + глаза</span>
                                <hr />
                                <span>13'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Шея лифтинг "Ниффертити"</span>
                                <hr />
                                <span>10'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Лечение бруксизма</span>
                                <hr />
                                <span>12'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Фотоомоложение Lumecca/M22</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Протокол акне</span>
                                <hr />
                                <span>12'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Протокол купероз</span>
                                <hr />
                                <span>12'000</span>
                            </div>

                            <div className="prices-screen__text-container">
                                <span>Протокол пигментация</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Протокол лицо и шея</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Протокол лицо, шея, декольте</span>
                                <hr />
                                <span>40'000</span>
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Плазма PRP</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>RegenLab blue 8ml</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>RegenLab red 8ml</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>RegenLab red ACR plus 3 пробирки по 8ml</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Липолитики</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Aqualyx 10ml</span>
                                <hr />
                                <span>8'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Увеличение губ</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Stylage lips</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Stylage m</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia lips 1ml</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia flux 1ml</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Belotero lips 0.5ml</span>
                                <hr />
                                <span>15'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Объемное моделирование лица</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Stylage XL</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Stylage L</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia intense</span>
                                <hr />
                                <span>18'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia stimulate</span>
                                <hr />
                                <span>20'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Neauvia man</span>
                                <hr />
                                <span>18'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Увеличение ягодиц</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Sofiderm (гиалуроновая кислота) 20ml</span>
                                <hr />
                                <span>30'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Sculptra (полимолочная кислота) 10ml</span>
                                <hr />
                                <span>50'000</span>
                            </div>
                            <br />
                            <h5>От 3 флаконов скидка 15%</h5>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Векторный лифтинг Radiesse</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Radiesse 1.5ml</span>
                                <hr />
                                <span>28'000</span>
                            </div>
                            <div className="prices-screen__text-container">
                                <span>Radiesse 3ml</span>
                                <hr />
                                <span>46'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Генетический тест</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Стоимость</span>
                                <hr />
                                <span>18'000</span>
                            </div>
                            <br />
                            <h5>При заказе от двух панелей скидка 20%</h5>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<AddIcon fontSize='large' />}
                    >
                        <div className="prices-screen__header">Полимолочная кислота</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="prices-screen__body">
                            <div className="prices-screen__text-container">
                                <span>Sculptra - 1 флакон</span>
                                <hr />
                                <span>50'000</span>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Element>
    );
};