import { Element } from 'react-scroll';
import './us-instagram-screen.scss';

import dilya from '../../../public/instagram/dilya.jpg';
import choes from '../../../public/instagram/choes.jpg';
import nithya from '../../../public/instagram/nithya.jpg';
import save from '../../../public/instagram/save.jpg';
import dilyaWebP  from '../../../public/instagram/dilya.webp';
import choesWebP  from '../../../public/instagram/choes.webp';
import nithyaWebP  from '../../../public/instagram/nithya.webp';
import saveWebP  from '../../../public/instagram/save.webp';


export default function UsInstagramScreen() {

    return (
        <Element name="instagram" className="us-instagram-screen__wrapper">
            <div className="us-instagram-screen__container">
                <div className="us-instagram-screen__us">Наш</div>
                <div className="us-instagram-screen__instagram">INSTAGRAM</div>
                <div className="us-instagram-screen__images-container">
                    <a href="https://www.instagram.com/p/CN0w0DHl-8a/" target="_blank" rel="noreferrer" className="us-instagram-screen__image-container">
                        <picture>
                            <source srcSet={nithyaWebP} type="image/webp" />
                            <img src={nithya} alt="" className="us-instagram-screen__image" />
                        </picture>
                    </a>
                    <div className="us-instagram-screen__image-container">
                        <a href="https://www.instagram.com/p/CLDru1SsX_h/" target="_blank" rel="noreferrer">
                            <picture>
                                <source srcSet={choesWebP} type="image/webp" />
                                <img src={choes} alt="" className="us-instagram-screen__image" />
                            </picture>
                        </a>
                    </div>
                    <div className="us-instagram-screen__image-container">
                        <a href="https://www.instagram.com/p/CMPMEXTlTHe/" target="_blank" rel="noreferrer">
                            <picture>
                                <source srcSet={dilyaWebP} type="image/webp" />
                                <img src={dilya} alt="" className="us-instagram-screen__image" />
                            </picture>
                        </a>
                    </div>
                    <div className="us-instagram-screen__image-container">
                        <a href="https://www.instagram.com/p/CNo7amZKeBj/" target="_blank" rel="noreferrer">
                            <picture>
                                <source srcSet={saveWebP} type="image/webp" />
                                <img src={save} alt="" className="us-instagram-screen__image" />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
        </Element>
    );
};