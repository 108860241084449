import { Element } from 'react-scroll';
import { Link } from 'react-scroll';
import styled, { css } from 'styled-components';
import { desktop, mobile } from "../../utils/media";
import girl from '../../public/images/headgirl.jpg';
import logo from '../../public/images/blacklogo.png';


const Wrapper = styled(Element)`
    background-image: url(${girl});
    min-height: 93vh;
    background-size: cover;
    background-position-x: center;
`;
const TopContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 57%;
    padding: 10px 0 0 10px;
`;
const TopContentLogo = styled.img`
    width: 100%;
    max-width: 230px;
    margin: 20px;
    object-fit: contain;
    @media(min-width: 1900px) {
        position: fixed;
    }
`;
const TopContentText = styled.h3`
    flex-grow: 1;
    padding: 20px 0;
    text-align: center;
    
    p {
        text-indent: 0;
    }

    @media(min-width: 1900px) {
        margin-left: 230px;
    }

    @media(max-height: 550px) {
        display: none;
    }
`;
const Appointment = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 20vh;
    left: 4vw;
    ${desktop(css`
        left: 10vw;
    `)}
`;
const AppointmentText = styled.div`
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 30px 0;
    ${mobile(css`
      font-size: 20px;
    `)}
    ${desktop(css`
      font-size: 16px;
    `)}
`;
const AppointmentButton = styled.button`
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 10px 40px;
`;


export function HomeScreen() {

    return (
        <Wrapper name="home">
            <TopContent>
                <TopContentLogo src={logo} />
                <TopContentText>
                    <p>Лучшая инвестиция - это</p>
                    <p>инвестиция в себя</p>
                </TopContentText>
            </TopContent>
            <Appointment>
                <AppointmentText>Запишитесь к нам на консультацию</AppointmentText>
                <Link to="appointment" smooth={true}><AppointmentButton>Записаться</AppointmentButton></Link>
            </Appointment>
        </Wrapper>
    );

};