import { Link } from 'react-scroll';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './footer-screen.scss';

import logo from '../../../public/small-logo.png';
import logoWebP from '../../../public/small-logo.webp';


export default function FooterScreen() {

    return (
        <section className="footer-screen__wrapper">
            <div className="footer-screen__container">
                <div className="footer-screen__block-with-logo">
                    <div className="footer-screen__icons">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dr.sukhova">
                            <div className="footer-screen__white-circle">
                                <InstagramIcon />
                            </div>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/dr_sukhova">
                            <div className="footer-screen__white-circle">
                                <TelegramIcon />
                            </div>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://wa.me/79296062382">
                            <div className="footer-screen__white-circle">
                                <WhatsAppIcon />
                            </div>
                        </a>
                    </div>
                    <picture>
                        <source srcSet={logoWebP} type="image/webp" />
                        <img src={logo} alt="" className="footer-screen__logo" />
                    </picture>
                </div>
                <div>
                    <div className="footer-screen__header">Информация</div>
                    <Link to="about" smooth={true} className="footer-screen__link">О нас</Link>
                    <Link to="services" smooth={true} className="footer-screen__link">Услуги</Link>
                    <Link to="prices" smooth={true} className="footer-screen__link">Цены на процедуру</Link>
                    <Link to="appointment" smooth={true} className="footer-screen__link">Запись к косметологу</Link>
                </div>
                <div>
                    <div className="footer-screen__header">Контакты</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://go.2gis.com/ZGHwQ" className="footer-screen__link">Адрес: Москва, Люсиновская улица, 68</a>
                    <a href="tel:+79296062382" className="footer-screen__link">Телефон: +7 929 606 23 82</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dr.sukhova" className="footer-screen__link">Instagram: @dr.sukhova</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://t.me/drsukhova" className="footer-screen__link">Telegram: @drsukhova</a>
                </div>
            </div>
        </section>
    );
};