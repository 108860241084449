import { useState } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Element } from 'react-scroll';
import { ReactComponent as Arr } from '../../../public/leftArr.svg';
import './reviews-screen.scss';

import olga from '../../../public/reviews/olga.jpg';
import katya from '../../../public/reviews/katya.jpg';
import havrosh from '../../../public/reviews/havrosh.jpg';
import olgaWebP from '../../../public/reviews/olga.webp';
import katyaWebP from '../../../public/reviews/katya.webp';
import havroshWebP from '../../../public/reviews/havrosh.webp';

SwiperCore.use([Navigation, Pagination, Scrollbar, EffectFade]);


export default function RewiewsScreen() {
    const [height, setHeight] = useState<number>();

    const setHeightSlider = (swiper: SwiperCore) => {
        setTimeout(() => {
            const el = swiper.slides.filter(element => element.classList.contains('swiper-slide-active'))[0];
            const height = el.clientHeight;
            setHeight(height)
        }, 0);
    }


    return (
        <Element name="reviews" className="reviews-screen__wrapper" style={height ? { height: height + window.innerWidth * 0.08 + 30 } : {}}>
            <h1>Отзывы</h1>
            <div className="reviews-screen__flex-container">
                <div
                    className="swiper-button-prev reviews-screen__swiper-button reviews-screen__swiper-button-transform"
                    style={height ? {marginTop: height / 2} : {}}
                >
                    <Arr />
                </div>
                <div className="reviews-screen__container">
                    <Swiper
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }}
                        slidesPerView={1}
                        loop={true}
                        spaceBetween={30}
                        onSlideChange={setHeightSlider}
                        onOrientationchange={setHeightSlider}
                        onResize={setHeightSlider}
                    >
                        <SwiperSlide>
                            <div className="reviews-screen__slide">
                                <div className="reviews-screen__rewiews">
                                    Я просмотрела все работы от и до.
                                    Далее написала нашему любимому врачу.
                                    Была приятно идивлена скорости ответа и внимательности к моей персоне.
                                    Записалась на консультацию. И в тот же визит прошла процедуру Радиес.
                                    Благодаря нежности, внимательности, деликатности,
                                    и полной уверенности в своём профессионализме,
                                    Дилана порадовала меня данной процедурой. Спасибо Вам, что вы есть.
                                </div>
                                <picture className="care-screen__picture2">
                                    <source srcSet={olgaWebP} type="image/webp" />
                                    <img src={olga} alt="" className="reviews-screen__avatar" />
                                </picture>
                                <div className="reviews-screen__name">Ольга</div>
                                <div className="reviews-screen__instagram">@olganemova02</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews-screen__slide">
                                <div className="reviews-screen__rewiews">
                                    Впервые увидела Вас в качестве эксперта одного видео ролика на ютуб,
                                    сейчас точно не вспомню но кажется это был ролик Кати Конасовой
                                    Потом я пришла к Вам впервые на чистку,
                                    и с того времени других врачей дерматологов-косметологов для меня не существует, только Вы
                                </div>
                                <picture className="care-screen__picture2">
                                    <source srcSet={havroshWebP} type="image/webp" />
                                    <img src={havrosh} alt="" className="reviews-screen__avatar" />
                                </picture>
                                <div className="reviews-screen__name">Александра</div>
                                <div className="reviews-screen__instagram">@dr.havrosh</div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews-screen__slide">
                                <div className="reviews-screen__rewiews">
                                    Я временно проживаю в России,
                                    ранее пользовалась услугами косметологии в Латвии.
                                    Из-за пандемии пришлось искать услуги здесь.
                                    Я нашла много врачей, но кроме пиара и собственного любования доктора я ничего толкового не увидела:)
                                    странно это продают в России, я была удивлена:) но продолжала поиски.
                                    Я подписалась на многих врачей в Инстаграм и пол года следила за всеми.
                                    Твой Инстаграм, Диля, победил: все четко, коротко, по делу, интеллигентно,
                                    тактично, нет лишнего и стремления делать неживых кукол.
                                    Поразило знание конкурентов (если их можно так назвать)
                                    Все кто вызывал мой интерес, мы обсуждали с тобой,
                                    ты проясняла что к чему, я за это благодарна тебе:) я твой пациент,
                                    изменения есть и ты стараешься сделать меня прекрасной в мои 42:)
                                    я довольна, редкий человек ТЫ, с прекрасной душой,
                                    если нужен дополнительный совет про обследования по здоровью -
                                    ты тоже поможешь и не оставишь с проблемой. До встречи, доктор.
                                </div>
                                <picture className="care-screen__picture2">
                                    <source srcSet={katyaWebP} type="image/webp" />
                                    <img src={katya} alt="" className="reviews-screen__avatar" />
                                </picture>
                                <div className="reviews-screen__name">Екатерина</div>
                                <div className="reviews-screen__instagram">@jekaterinabelaja</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div
                    className="swiper-button-next reviews-screen__swiper-button"
                    style={height ? {marginTop: height / 2 } : {}}
                >
                    <Arr />
                </div>
            </div>
        </Element>
    );
};