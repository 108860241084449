import { createStore, createEvent, sample } from 'effector';

export const $appointment = createStore<boolean>(false);

export const activeAppointment = createEvent<void>();
export const dropAppointment = createEvent<void>();

sample({
    clock: activeAppointment,
    fn: () => true,
    target: $appointment,
});

sample({
    clock: dropAppointment,
    fn: () => false,
    target: $appointment,
});