import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Element, scroller } from 'react-scroll';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import { mobile } from '../../utils/media';


const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'block',
        margin: '20px 0 1px 0'
    },
});

const Wrapper = styled(Element)`
    background-color: #EFE0CD;
    padding: 0 15px;
`;
const Container = styled.div`
    max-width: 960px;
    margin: 0 auto;
`;
const Card = styled.div`
    margin: 40px auto;
    max-width: 650px;
    background-color: white;
    box-shadow: 10px 10px 20px 10px rgb(171 159 163);
    padding: 3rem; 
`;
const Text = styled.div`
    text-align: center;
    margin: 0 0 2rem 0;
    line-height: 30px;
    font-weight: 700;
`;
const Line = styled.div`
    border-bottom: 1px solid black;
    width: 100%;
    margin-bottom: 1rem;
`;
const Error = styled.div`
    color: red;
    ${mobile(css`
        display: inline;
        margin: 0 0 0 1rem;
    `)}
`;
const SuccesfulSend = styled.button`
    &:hover,
    & {
        background-color: #28a745;
        border-radius: 3px;
        color: white;
        font-weight: 500;
        border: none;
        margin-top: 30px;
    }
`;
const Button = styled.button`
    margin-top: 50px;
`;


export function AppointmentScreen() {
    const [ok, setOk] = useState<boolean | null>(null);
    const [location] = useState(useLocation());

    const classes = useStyles();
    useEffect(() => {
        if (location.hash === "#appointment") {
            scroller.scrollTo('appointment', {
                smooth: true
            })
        }
    }, [location])

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            message: ''
        },
        validationSchema: yup.object({
            name: yup.string()
                .required('Обязательное поле'),
            phone: yup.string()
                .matches(/^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/, 'Некорректный номер')
                .required('Обязательное поле'),
            message: yup.string()
        }),
        onSubmit: async (values) => {
            const response = await fetch('/api/message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            });
            if (response.status === 200) {
                setOk(true);
                setTimeout(() => setOk(null), 3000);
            }
        },
    });

    return (
        <Wrapper name="appointment">
            <Container>
                <Card>
                    <Text>
                        Запишитесь к нам на консультацию или задайте вопрос.
                    </Text>
                    <form onSubmit={formik.handleSubmit}>
                        <label htmlFor="name">Ваше имя</label>
                        {formik.touched.name && formik.errors.name ? <Error>{formik.errors.name}</Error> : null}
                        <InputBase endAdornment={null} startAdornment={null} className={classes.root}
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        <Line />
                        <label htmlFor="phone">Ваш номер телефона</label>
                        {formik.touched.phone && formik.errors.phone ? <Error>{formik.errors.phone}</Error> : null}
                        <InputBase className={classes.root}
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                        <Line />
                        <label htmlFor="message">Ваше сообщение</label>
                        <InputBase className={classes.root}
                            multiline={true}
                            id="message"
                            name="message"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                        />
                        <Line />
                        {ok ? <SuccesfulSend disabled>Отправлено</SuccesfulSend> : <Button>Отправить</Button>}
                    </form>
                </Card>
            </Container>
        </Wrapper>
    );
};