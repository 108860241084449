import { Dialog } from '@material-ui/core';
import { Link } from 'react-scroll';
import { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';
import { mobile } from '../utils/media';

const Wrapper = styled.div`
    width: 100%;
    height: 0px;
    position: relative;
`;
const useStyles = makeStyles({
    root: {
        "& .MuiDialog-paper": {
            background: "none",
            boxShadow: "none"
        },
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.6)"
        }
    }
});
const MenuButton = styled.div`
    top: 24px;
    right: 24px;
    cursor: pointer;
    position: fixed;
    width: 40px;
    height: 35px;
    z-index: 10000;
    ${mobile(css`
        right: 48px;
        top: 64px;
    `)}
`;
const MenuBurger = styled.div<{ open: boolean }>`
    &::before, 
    &::after, 
    span {
        content: '';
        background-color: black;
        position: absolute;
        width: 100%;
        height: 5px;
        transition: all 0.3s ease 0s;
    }
    &::before {
        top: ${props => props.open ? "15px" : "0"};
        transform: ${props => props.open ? "rotate(45deg)" : "none"};

    }
    &::after {
        bottom: ${props => props.open ? "15px" : "0"};
        transform: ${props => props.open ? "rotate(-45deg)" : "none"};
    }
    span {
        top: 15px;
        display: ${props => props.open ? "none" : "inline"};
    }
`;
const MenuList = styled.nav`
    max-width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60vh;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    max-height: 400px;
    @media(max-height: 600px) {
        height: 80vh;
    }
    div {
        cursor: pointer;
    }
`;

export function Menu() {
    const [open, setOpen] = useState(false);

    const handleOpenMenu = useCallback(() => {
        setOpen(prev => (!prev));
    }, []);

    const classes = useStyles();

    return (
        <Wrapper>
            <MenuButton onClick={handleOpenMenu}>
                <MenuBurger open={open}><span></span></MenuBurger>
            </MenuButton>
            <Dialog
                className={classes.root}
                open={open}
                onClose={handleOpenMenu}
                disableScrollLock={true}
            >
                <MenuList>
                    <Link to="home" smooth={true} onClick={handleOpenMenu}><div>Главная</div></Link>
                    <Link to="services" smooth={true} onClick={handleOpenMenu}><div>Услуги</div></Link>
                    <Link to="about" smooth={true} onClick={handleOpenMenu}><div>Обо мне</div></Link>
                    <Link to="prices" smooth={true} onClick={handleOpenMenu}><div>Цены на процедуры</div></Link>
                    <Link to="reviews" smooth={true} onClick={handleOpenMenu}><div>Отзывы</div></Link>
                    <Link to="instagram" smooth={true} onClick={handleOpenMenu}><div>Инстаграмм</div></Link>
                </MenuList>
            </Dialog>
        </Wrapper>
    );
};